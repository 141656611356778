import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

// Components:
import { Button, ButtonHollow, EditProperty, LoadingSpinner } from 'components';

// Styles:
import './style.css';
import 'react-table/react-table.css';

// Types
import Category from 'types/Category';
import Language from 'types/Language';

// Utils:
import { get, post } from 'utils/AJAX';
import getLanguages from 'utils/getLanguages';
import { useToast } from 'components/Toast/useToast';

// =========================================================
interface Props extends RouteComponentProps {}
// =========================================================

const CategoryEdit: FC<Props> = ({ history }) => {
  const id = window.location.pathname.split('/')[2];
  const { addToast } = useToast();
  const [category, setCategory] = useState<Category>({
    translations: null,
    parent: null,
    position: 0
  });
  const [languages, setLanguages] = useState<Language[]>([]);
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [parentName, setParentName] = useState('');
  const [saveSuccessfull, setSaveSuccessfull] = useState(false);
  const [saving, setSaving] = useState(false);
  const [searchResults, setSearchResults] = useState<Category[]>([]);
  const [query, setQuery] = useState('');

  // =================================

  const saveChanges = async () => {
    const newData =
      id === 'new'
        ? {
            ...category,
            parent: category && category.parent ? category.parent : null
          }
        : {
            ...category,
            id,
            parent: category && category.parent ? category.parent : null
          };
    setSaving(true);
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/category/save`, {
      data: newData
    });
    if (data) {
      setSaveSuccessfull(true);
      addToast({
        title: 'Success',
        message: 'Category saved successfully',
        type: 'success',
        duration: 5000,
        id: 'save-category-toast'
      });
    }
    if (error) {
      console.log(error);
      addToast({
        title: 'Error',
        message: "Couldn't save category",
        type: 'error',
        duration: 5000,
        id: 'save-category-toast'
      });
    }
    setSaving(false);
  };

  const searchCategories = async () => {
    setSearching(true);
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/category/search`, {
      data: {
        rows: 1000,
        offset: 0,
        query: query,
        sort: {
          field: 'created',
          order: 'ASC'
        }
      }
    });

    if (data) {
      setSearchResults(data);
    }
    if (error) {
      console.log(error);
    }
    setSearching(false);
  };

  // =================================

  useEffect(() => {
    console.log(category);
  }, [category]);

  useEffect(() => {
    const fetchCategory = async () => {
      setLoading(true);
      const { data, error } = await get(`${process.env.REACT_APP_API_URL}/category/get/${id}`);
      if (data) {
        if (data.position) {
          setCategory(data);
        } else {
          setCategory({ ...data, position: 0 });
        }
        setLoading(false);

        return data;
      }
      if (error) {
        console.log(error);
        setLoading(false);

        return false;
      }
      return false;
    };
    const initLanguages = async (fC: Category) => {
      const { data, error } = await getLanguages();
      if (error) {
        console.log(error);
      }
      if (data) {
        setLanguages(data);
        const nameObject = { ...fC.translations };
        data.forEach((l: Language) => {
          nameObject[l.id] = fC && fC.translations && fC.translations[l.id] ? fC.translations[l.id] : { name: '' };
        });
        setCategory({ ...fC, translations: nameObject });
      }
    };

    const updateCategory = async () => {
      if (id && id !== 'new') {
        const data = await fetchCategory();
        if (data) {
          initLanguages(data);
        }
      } else {
        initLanguages(category);
      }
    };
    updateCategory();
  }, [id]);

  useEffect(() => {
    if (languages && languages.length > 0 && category) {
      if (category.parent && languages) {
        const fetchCategoryParent = async () => {
          const { data, error } = await get(`${process.env.REACT_APP_API_URL}/category/get/${category.parent.id}`);
          if (data) {
            setParentName(data.translations[languages[0].id].name);
          }
          if (error) {
            console.log(error);
          }
        };
        fetchCategoryParent();
      }
    }
  }, [languages]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="Category-Wrapper">
      <div>
        <h1>{id === 'new' ? 'Create Category' : 'Edit Category'}</h1>
      </div>
      <h3>Name</h3>
      {languages &&
        languages.length > 0 &&
        languages.map(l => (
          <div key={`Input - ${l.id}`}>
            <EditProperty description={`${l.name}:`}>
              <input
                className="EditProperty-Input-Textfield"
                onChange={event => {
                  setCategory({
                    ...category,
                    translations: {
                      ...category.translations,
                      [l.id]: { name: event.target.value }
                    }
                  });
                }}
                placeholder={`Enter Name`}
                value={
                  category && category.translations && category.translations[l.id] && category.translations[l.id].name ? category.translations[l.id].name : ''
                }
              />
            </EditProperty>
          </div>
        ))}

      <h3>Position</h3>
      <EditProperty description="Position:">
        <input
          className="EditProperty-Input-Textfield"
          onChange={event => {
            setCategory({
              ...category,
              position: parseInt(event.target.value)
            });
          }}
          placeholder="Enter Position"
          type="number"
          value={category && category.position !== null ? category.position : 0}
        />
      </EditProperty>

      <h3>Add Parent</h3>

      <EditProperty description={'Parent:'}>
        {category && category.parent ? (
          <div>
            <span style={{ marginRight: '.5rem' }}>{parentName}</span>
            <ButtonHollow className="Category-Result-Button" onClick={() => setCategory({ ...category, parent: null })}>
              Remove
            </ButtonHollow>
          </div>
        ) : (
          <div>-</div>
        )}
      </EditProperty>
      <div style={{ marginTop: '1rem' }}>
        <form
          action=""
          onSubmit={e => {
            e.preventDefault();
            searchCategories();
          }}
        >
          <input
            className="EditProperty-Input-Textfield"
            onChange={event => {
              setQuery(event.target.value);
            }}
            placeholder={'Search categories ...'}
            value={query}
          />
        </form>
      </div>

      {searching && <LoadingSpinner />}

      {searchResults && searchResults.length > 0 && (
        <div className="Category-Results-Wrapper">
          {searchResults.map(sR => {
            return (
              <div className="Category-Result" key={`Search-Result-${sR.id}`}>
                {sR.translations[languages[0].id].name}
                <ButtonHollow
                  className="Category-Result-Button"
                  onClick={() => {
                    setCategory({ ...category, parent: { id: sR.id } });
                    setParentName(sR.translations[languages[0].id].name);
                    setSearchResults([]);
                  }}
                >
                  Add
                </ButtonHollow>
              </div>
            );
          })}
        </div>
      )}

      <div className="Category-ButtonContainer">
        <ButtonHollow onClick={() => history.push('/categories')}>Cancel</ButtonHollow>
        <Button btnType="first" onClick={saveChanges}>
          Save
        </Button>
      </div>
      {saveSuccessfull && <div className="Save-Confirm">Saved Changes.</div>}
      {saving && <LoadingSpinner />}
    </div>
  );
};

export default CategoryEdit;
