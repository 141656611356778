import React from 'react';
import { EditProperty, UploadExcel } from 'components';

export const DeviceUpload = () => {
  return (
    <EditProperty description={`Device Import`}>
      <UploadExcel shouldRenderResponse url={`/device/import/devices`}>
        Import xlsx File
      </UploadExcel>
    </EditProperty>
  );
};
