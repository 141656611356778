import React from 'react';
import { LoadingSpinner } from 'components';
import { useGetVariantDevices } from 'page/DeviceEdit/useGetVariantDevices';
import { Link } from 'react-router-dom';

export const Variants = ({ variants }: { variants: string[] }) => {
  const { isLoading, devices } = useGetVariantDevices(variants);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <ul>
      {devices.map(device => (
        <Link className="Variant-Link" key={device.id} to={`/devices/${device.id}`} target="_blank" rel="noopener noreferrer">
          <li style={{ textDecoration: 'none', margin: '5px 0 ' }}>
            {device?.moneyCode} - {device?.translations['vdrqR'].name}
          </li>
        </Link>
      ))}
    </ul>
  );
};
