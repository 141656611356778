import React from 'react';
import { ToastType } from 'components/Toast/ToastProvider';
import { useToast } from 'components/Toast/useToast';
import { useEffect } from 'react';
import './Toast.scss';

export const ToastItem = ({ toast }: { toast: ToastType }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(toast.id);
    }, toast.duration);

    return () => {
      clearTimeout(timer);
    };
  }, [toast.id, toast.duration, removeToast]);

  return (
    <div className={`toast toast-${toast.type}`}>
      <div className="toast-content">
        <div className="toast-title">{toast.title}</div>
        <div className="toast-message">{toast.message}</div>
      </div>
      <button className="toast-close" onClick={() => removeToast(toast.id)}>
        X
      </button>
    </div>
  );
};
