import { Button, LoadingSpinner } from 'components';
import React, { FC, useState } from 'react';
import { post } from 'utils/AJAX';

type Status = 'idle' | 'loading' | 'error';

export const DuplicateButton: FC<{ deviceId: string }> = ({ deviceId }) => {
  const [status, setStatus] = useState<Status>('idle');

  if (!deviceId || deviceId === '' || deviceId === 'new') return null;

  const handleDuplicate = async () => {
    setStatus('loading');
    try {
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/device/duplicate`, {
        data: { id: deviceId }
      });

      if (data) {
        setStatus('idle');
        window.open(`/devices/${data.id}`, '_blank');
      } else if (error) {
        setStatus('error');
      }
    } catch (error) {
      setStatus('error');
    }
  };
  return (
    <div style={{ marginTop: '1rem', textAlign: 'center' }}>
      <Button btnType="first" disabled={status === 'loading'} onClick={handleDuplicate}>
        {status === 'loading' ? <LoadingSpinner size="15px" margin="auto" /> : 'Duplicate device'}
      </Button>
      {status === 'error' && <p style={{ color: 'red' }}>Error duplicating device</p>}
    </div>
  );
};

/* 

const deleteDevice = async () => {
    setDeleting(true);
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/device/delete`, {
      data: { id }
    });
    if (data) {
      setDeleting(false);

      history.push('/devices');
    } else if (error) {
      setDeleteError(true);
    }
    setDeleting(false);
  };

*/
