import React, { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';

// Components:
import { ButtonHollow } from 'components';

// Utils:
import { post } from 'utils/AJAX';
import { useToast } from 'components/Toast/useToast';

interface Props {
  id: string;
  parentType: 'device' | 'component';
  onUploadSuccess?: (data: any) => void;
}

export const UploadObject: FC<Props> = ({ id, parentType, onUploadSuccess }) => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { addToast } = useToast();

  const uploadObject = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    const url = parentType === 'device' ? `device/save/${id}/model/object` : `component/save/${id}/object`;
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/${url}`, {
      data: formData
    });

    if (data) {
      setSuccess(true);
      setError(false);
      if (typeof onUploadSuccess === 'function') {
        onUploadSuccess(data);
      }
      addToast({
        title: 'Success',
        message: 'Object uploaded',
        type: 'success',
        duration: 5000,
        id: 'upload-object-toast'
      });
    } else if (error) {
      setError(true);
      setSuccess(false);
      addToast({
        title: 'Error',
        message: 'Could not upload object',
        type: 'error',
        duration: 5000,
        id: 'upload-object-toast'
      });
    }
  };
  const onDrop = (files: File[]) => {
    uploadObject(files[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div>
      <span>
        <b>Component 3D Object</b>
      </span>
      {success && <p>Object Changed.</p>}
      {error && <p>Could not change Object.</p>}

      <div style={{ marginTop: '1rem' }}>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <ButtonHollow onClick={() => {}}>Upload Object</ButtonHollow>
        </div>
      </div>
    </div>
  );
};
