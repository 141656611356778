import React, { FC } from 'react';
import './style.css';

interface Props {
  className?: string;
  isDisabled?: boolean;
  onClick: () => void;
}

export const ButtonHollow: FC<Props> = ({ className, children, isDisabled, onClick }) => {
  return (
    <button className={'ButtonHollow ' + className} disabled={isDisabled} onClick={onClick}>
      {children}
    </button>
  );
};
