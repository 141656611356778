import React, { FC, useEffect, useState } from 'react';

// Components
import ComponentInput from './ComponentInput';

// Types
import { DeviceComponent } from 'types/Device';
import { Component } from 'types/Component';

// Utils
import getComponents from 'utils/getComponents';
import { post } from 'utils/AJAX';

interface Props {
  components: DeviceComponent[];
  componentResults: Component[];
  componentSearchStarted: boolean;
  filterComponent: string;
  setComponents: (comp: DeviceComponent[]) => void;
  setComponentResults: (res: Component[]) => void;
  setComponentSearchStarted: (started: boolean) => void;
  setFilterComponent: (filter: string) => void;
  updateScene?: () => void;
}

const DeviceComponents: FC<Props> = ({
  components,
  componentResults,
  componentSearchStarted,
  filterComponent,
  setComponents,
  setComponentResults,
  setComponentSearchStarted,
  setFilterComponent,
  updateScene
}) => {
  const [componentNames, setComponentNames] = useState([]);

  const searchComponent = async (query: string) => {
    setComponentSearchStarted(true);
    const { data, error } = await getComponents(query);
    if (data) {
      console.log(data);
      setComponentResults(data);
      // const filteredData = data.filter(
      //   (c: Component) =>
      //     !components.some(
      //       deviceComponent => deviceComponent.component.id === c.id
      //     )
      // );
      // console.log(filteredData);
      // setComponentResults(filteredData);
    }
    if (error) {
      console.log(error);
    }
    setComponentSearchStarted(true);
  };

  useEffect(() => {
    const fetchComponentNames = async () => {
      const { data } = await post(`${process.env.REACT_APP_API_URL}/component/search`, {
        data: {
          rows: 1000,
          offset: 0,
          query: '',
          sort: {
            field: 'name',
            order: 'ASC'
          }
        }
      });
      if (data) {
        setComponentNames(data);
      }
    };
    fetchComponentNames();
  }, []);

  return (
    <div>
      <h3 className="Device-SubCategory-Header">Device Components</h3>
      <div>
        <form
          onSubmit={e => {
            e.preventDefault();
            searchComponent(filterComponent);
          }}
        >
          <input
            className="EditProperty-Input-Textfield"
            onChange={event => {
              setFilterComponent(event.target.value);
            }}
            placeholder="Add Component"
            value={filterComponent}
          />
        </form>
      </div>
      {componentSearchStarted && (
        <div>
          <h4>Results</h4>
          <div>
            {componentResults && componentResults.length > 0
              ? componentResults.map(cR => (
                  <button
                    key={`Add-Component-Button-${cR.id}`}
                    onClick={() => {
                      // if (!components.some(c => c.component.id === cR.id)) {
                      setComponents([
                        ...components,
                        {
                          component: {
                            id: cR.id
                          },
                          position: { x: 0, y: 0, z: 0 },
                          scaling: { x: 1, y: 1, z: 1 },
                          fixed: false,
                          backside: false
                        }
                      ]);
                      if (typeof updateScene === 'function') updateScene();
                      // }
                    }}
                  >
                    {cR.name}
                  </button>
                ))
              : componentResults.length === 0 && <div>No components found.</div>}
          </div>
        </div>
      )}
      <div>
        <h4>Added Components</h4>
        {components &&
          components.length > 0 &&
          components.map((c, i) => (
            <ComponentInput
              key={`Device-Component-${i}-${c.component.id}`}
              index={i}
              components={components}
              component={c}
              componentNames={componentNames}
              setComponents={setComponents}
              updateScene={updateScene}
            />
          ))}
      </div>
    </div>
  );
};

export default DeviceComponents;
