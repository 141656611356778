import { ToastItem } from 'components/Toast/ToastItem';
import React, { useState } from 'react';

export type ToastType = {
  id: string;
  type: 'success' | 'error';
  title: string;
  message: string;
  duration: number;
};

export const ToastContext = React.createContext<{
  addToast: (toast: ToastType) => void;
  removeToast: (id: string) => void;
}>({
  addToast: () => {},
  removeToast: () => {}
});

export const ToastProvider: React.FC = ({ children }) => {
  const [toasts, setToasts] = useState<ToastType[]>([]);

  const addToast = (toast: ToastType) => {
    setToasts(prevToasts => [...prevToasts, toast]);
  };

  const removeToast = () => {
    setToasts([]);
  };

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <div className="toast-container">
        {toasts.map(toast => (
          <ToastItem key={toast.id} toast={toast} />
        ))}
      </div>
    </ToastContext.Provider>
  );
};
