import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

// Components:
import { ButtonHollow } from 'components';

// Utils:
import { post } from 'utils/AJAX';

export function UploadExcel(props: { url: string; buttonClassName?: string; className?: string; children?: any; shouldRenderResponse?: boolean }) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [response, setResponse] = useState<any>(null);

  const uploadObject = async (file: File) => {
    setLoading(true);
    setResponse(null);
    const formData = new FormData();
    formData.append('file', file);

    const { data, error } = await post(`${process.env.REACT_APP_API_URL}${props.url}`, {
      data: formData,
      timeout: 300000
    });

    if (data) {
      setSuccess(true);
      setError(false);
      setResponse(data);
    } else if (error) {
      setError(true);
      setSuccess(false);
    }
    setLoading(false);
  };

  const onDrop = (files: File[]) => {
    uploadObject(files[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
  });

  return (
    <div className={'UploadExcel ' + (props.className || '')}>
      {success && <p>Upload successfull.</p>}
      {error && <p>There was an error, please try again.</p>}
      <div>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <ButtonHollow onClick={() => {}} className={props.buttonClassName || ''}>
            {props.children}
          </ButtonHollow>
        </div>
      </div>
      {props.shouldRenderResponse && response && <pre>{JSON.stringify(response, null, 2)}</pre>}
    </div>
  );
}
